.pulsing-token {
  position: fixed;
  bottom: 45%;
  z-index: 10;
}

.token-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: rotate(20deg);
  animation: shake-pause 4s ease-in-out infinite;
}

@keyframes shake-pause {
  0%, 90%, 100% { transform: rotate(20deg); }
  92% { transform: rotate(15deg); }
  94% { transform: rotate(25deg); }
  96% { transform: rotate(15deg); }
  98% { transform: rotate(25deg); }
}