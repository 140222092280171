.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #87CEEB;
    padding: 2rem;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-title {
    font-family: 'Press Start 2P', cursive;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .modal-text {
    font-family: Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .modal-close-btn {
    font-family: 'Press Start 2P', cursive;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .modal-close-btn:hover {
    background-color: #45a049;
  }